'use client'

import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { X, Plus, Code, ArrowLeft, ArrowRight, Eye } from 'lucide-react'
import MultipleSelector from '@/components/molecules/iteration-details/CreateIteration/MultipleSelector'

const optionsSchema = z.array(z.object({ value: z.string(), label: z.string(), group: z.string() }))

type Options = z.infer<typeof optionsSchema>

const formSchema = z.object({
  prospectName: z.string().min(1, { message: 'Prospect Name is required' }),
  prospectWebsite: z.string().min(1, { message: 'Prospect Website is required' }),
  projectTemplate: z.string().nullable(),
  technologies: optionsSchema.nonempty({ message: 'Technologies are required' }),
  whatToBuild: z.string().min(1, { message: 'What to build is required' }),
  technicalRequirements: z.string(),
  prospectRequirements: z.string(),
})

type FormData = z.infer<typeof formSchema>

const technologiesOptions: Options = [
  { value: 'shopify-hydrogen', label: 'Shopify Hydrogen', group: 'Commerce Engine' },
  { value: 'commercetools', label: 'Commercetools', group: 'Commerce Engine' },
  { value: 'shopware', label: 'Shopware', group: 'Commerce Engine' },
  { value: 'bigcommerce', label: 'BigCommerce', group: 'Commerce Engine' },
  { value: 'contentful', label: 'Contentful', group: 'CMS' },
  { value: 'sanity', label: 'Sanity', group: 'CMS' },
  { value: 'strapi', label: 'Strapi', group: 'CMS' },
  { value: 'react', label: 'React', group: 'Frontend' },
  { value: 'angular', label: 'Angular', group: 'Frontend' },
  { value: 'vue', label: 'Vue', group: 'Frontend' },
]

const projectTemplates: {
  key: string
  name: string
  description: string
  technologies: Options
  whatToBuild: string
  technicalRequirements: string
  prospectRequirements: string
}[] = [
  {
    key: 'demo-store',
    name: 'Demo Store',
    description: 'Around 10 products',
    technologies: [
      { value: 'shopify-hydrogen', label: 'Shopify Hydrogen', group: 'Commerce Engine' },
      { value: 'react', label: 'React', group: 'Frontend' },
      { value: 'contentful', label: 'Contentful', group: 'CMS' },
    ],
    whatToBuild: 'A small e-commerce store with basic product listing and checkout functionality.',
    technicalRequirements: 'Shopify API integration, responsive design, basic SEO',
    prospectRequirements: 'Easy to manage inventory, simple checkout process',
  },
  {
    key: 'complex-shop',
    name: 'Complex Shop',
    description: '5+ categories',
    technologies: [
      { value: 'angular', label: 'Angular', group: 'Frontend' },
      { value: 'commercetools', label: 'Commercetools', group: 'Commerce Engine' },
      { value: 'sanity', label: 'Sanity', group: 'CMS' },
    ],
    whatToBuild: 'A multi-category e-commerce platform with advanced search and filtering.',
    technicalRequirements:
      'Server-side rendering, GraphQL API, Stripe payment integration, advanced product filtering',
    prospectRequirements: 'Multiple product categories, user accounts, wish lists',
  },
  {
    key: 'enterprise',
    name: 'Enterprise',
    description: '3+ stores',
    technologies: [
      { value: 'vue', label: 'Vue', group: 'Frontend' },
      { value: 'shopware', label: 'Shopware', group: 'Commerce Engine' },
      { value: 'strapi', label: 'Strapi', group: 'CMS' },
    ],
    whatToBuild: 'A scalable multi-store e-commerce ecosystem with shared backend services.',
    technicalRequirements:
      'Microservices architecture, Kubernetes deployment, shared authentication service, data synchronization between stores',
    prospectRequirements:
      'Multiple storefronts, centralized inventory management, cross-store analytics',
  },
]

const helpContentWithExamples = {
  prospectName: {
    text: "Enter the name of the prospect or company you're creating this project for.",
    examples: [],
  },
  prospectWebsite: {
    text: 'Enter the website URL of the prospect. This helps in understanding their current online presence.',
    examples: [],
  },
  projectTemplate: {
    text: "Select a pre-defined project template that best fits your prospect's needs. This will pre-fill some fields with suggested content.",
    examples: [],
  },
  technologies: {
    text: 'List the main technologies, frameworks, or platforms you plan to use for this project.',
    examples: ['React, Node.js, MongoDB', 'Vue.js, Express, PostgreSQL', 'Angular, Django, MySQL'],
  },
  whatToBuild: {
    text: 'Describe the main features and functionality of the project you want to build.',
    examples: [
      'A responsive e-commerce website with user authentication and payment integration',
      'A real-time chat application with video calling capabilities',
      'A project management tool with task tracking and team collaboration features',
    ],
  },
  technicalRequirements: {
    text: 'List any specific technical requirements or constraints for the project.',
    examples: [
      'RESTful API, responsive design, OAuth 2.0 authentication',
      'WebSocket for real-time updates, cloud storage integration, mobile-first approach',
      'GraphQL API, containerized microservices, CI/CD pipeline',
    ],
  },
  prospectRequirements: {
    text: 'Note any specific requirements or preferences mentioned by the prospect.',
    examples: [
      'User-friendly interface, fast loading times, secure payment processing',
      'Cross-platform compatibility, offline mode support, end-to-end encryption',
      'Customizable dashboards, role-based access control, detailed analytics',
    ],
  },
}

const HelpContent = ({
  selectedField,
  onExampleClick,
}: {
  selectedField: string | null
  onExampleClick: (field: string, value: string) => void
}) => {
  const content = selectedField
    ? helpContentWithExamples[selectedField as keyof typeof helpContentWithExamples]
    : null

  return (
    <div className="h-full overflow-y-auto bg-gray-100 p-6">
      <h3 className="mb-2 text-lg font-semibold">Help Guide</h3>
      {content ? (
        <>
          <p className="mb-4 text-sm text-gray-600">{content.text}</p>
          {content.examples.length > 0 && (
            <div>
              <h4 className="mb-2 text-sm font-semibold">Example Values:</h4>
              <ul className="list-disc space-y-2 pl-5">
                {content.examples.map((example, index) => (
                  <li key={index} className="text-sm">
                    <button
                      onClick={() => onExampleClick(selectedField, example)}
                      className="text-blue-600 hover:underline focus:outline-none"
                    >
                      {example}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <p className="text-sm text-gray-600">Select a field to see specific help content.</p>
      )}
    </div>
  )
}

const ProjectTemplateCard = ({
  template,
  selected,
  onClick,
}: {
  template: (typeof projectTemplates)[0]
  selected: boolean
  onClick: () => void
}) => (
  <div
    className={`w-[calc(33.33%-0.67rem)] flex-shrink-0 flex-grow-0 cursor-pointer rounded-lg border p-4 transition-colors ${
      selected ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-blue-300'
    }`}
    onClick={onClick}
  >
    <h3 className="font-semibold">{template.name}</h3>
    <p className="text-sm text-gray-600">{template.description}</p>
  </div>
)

const FormStep = ({
  onSubmit,
  initialValues,
  onSeePrompt,
}: {
  onSubmit: (data: FormData) => void
  initialValues: Partial<FormData>
  onSeePrompt: () => void
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid: formIsValid },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  console.log('formIsValid: ', formIsValid)
  console.log('errors: ', errors)

  const [showTechnicalReq, setShowTechnicalReq] = useState(!!initialValues.technicalRequirements)
  const [showProspectReq, setShowProspectReq] = useState(!!initialValues.prospectRequirements)
  const [selectedField, setSelectedField] = useState<string | null>(null)
  const selectedTemplate = watch('projectTemplate')

  const handleTemplateClick = (templateKey: string) => {
    if (selectedTemplate === templateKey) {
      setValue('projectTemplate', null, { shouldValidate: true })
      setValue('technologies', null, { shouldValidate: true })
      setValue('whatToBuild', '', { shouldValidate: true })
      setValue('technicalRequirements', '', { shouldValidate: true })
      setValue('prospectRequirements', '', { shouldValidate: true })
      setShowTechnicalReq(false)
      setShowProspectReq(false)
    } else {
      setValue('projectTemplate', templateKey, { shouldValidate: true })
      const template = projectTemplates.find(t => t.key === templateKey)
      if (template) {
        // ts-ignore
        setValue('technologies', template.technologies as FormData['technologies'], {
          shouldValidate: true,
        })
        setValue('whatToBuild', template.whatToBuild, { shouldValidate: true })
        setValue('technicalRequirements', template.technicalRequirements, { shouldValidate: true })
        setValue('prospectRequirements', template.prospectRequirements, { shouldValidate: true })
        setShowTechnicalReq(true)
        setShowProspectReq(true)
      }
    }
  }

  const handleExampleClick = (field: string, value: string) => {
    setValue(field as keyof FormData, value, { shouldValidate: true })
  }

  return (
    <div className="flex h-full">
      <div className="flex w-2/3 flex-col p-6">
        <form onSubmit={handleSubmit(onSubmit)} className="flex-grow space-y-6 overflow-y-auto">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label htmlFor="prospectName" className="text-sm font-medium text-gray-700">
                Prospect Name
              </Label>
              <Input
                id="prospectName"
                {...register('prospectName')}
                className="mt-1"
                onFocus={() => setSelectedField('prospectName')}
              />
              {errors.prospectName && (
                <p className="mt-1 text-sm text-red-500">{errors.prospectName.message}</p>
              )}
            </div>
            <div>
              <Label htmlFor="prospectWebsite" className="text-sm font-medium text-gray-700">
                Prospect Website
              </Label>
              <Input
                id="prospectWebsite"
                {...register('prospectWebsite')}
                className="mt-1"
                onFocus={() => setSelectedField('prospectWebsite')}
              />
              {errors.prospectWebsite && (
                <p className="mt-1 text-sm text-red-500">{errors.prospectWebsite.message}</p>
              )}
            </div>
          </div>
          <div>
            <Label className="mb-2 block text-sm font-medium text-gray-700">Project Template</Label>
            <div
              className="flex flex-wrap gap-4"
              onClick={() => setSelectedField('projectTemplate')}
            >
              {projectTemplates.map(template => (
                <ProjectTemplateCard
                  key={template.key}
                  template={template}
                  selected={selectedTemplate === template.key}
                  onClick={() => handleTemplateClick(template.key)}
                />
              ))}
            </div>
          </div>
          <Controller
            name="technologies"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                {/* @ts-ignore */}
                <MultipleSelector
                  {...field}
                  defaultOptions={technologiesOptions as Options}
                  placeholder="Select technologies..."
                  emptyIndicator={
                    <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                      no results found.
                    </p>
                  }
                  groupBy="group"
                  badgeClassName="bg-black text-white"
                />
                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
          <div>
            <Label htmlFor="whatToBuild" className="text-sm font-medium text-gray-700">
              What do you want to build?
            </Label>
            <Textarea
              id="whatToBuild"
              {...register('whatToBuild')}
              className="mt-1"
              onFocus={() => setSelectedField('whatToBuild')}
            />
            {errors.whatToBuild && (
              <p className="mt-1 text-sm text-red-500">{errors.whatToBuild.message}</p>
            )}
          </div>
          <div className="flex space-x-4">
            {!showTechnicalReq && (
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowTechnicalReq(true)}
                className="border-gray-300 text-gray-700 hover:bg-gray-50"
              >
                <Plus className="mr-2 h-4 w-4" /> Technical Requirements
              </Button>
            )}
            {!showProspectReq && (
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowProspectReq(true)}
                className="border-gray-300 text-gray-700 hover:bg-gray-50"
              >
                <Plus className="mr-2 h-4 w-4" /> Prospect Requirements
              </Button>
            )}
          </div>
          {showTechnicalReq && (
            <div className="mt-4">
              <div className="mb-2 flex items-center justify-between">
                <Label htmlFor="technicalRequirements">Technical Requirements</Label>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    setValue('technicalRequirements', '')
                    setShowTechnicalReq(false)
                  }}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <Textarea
                id="technicalRequirements"
                {...register('technicalRequirements')}
                className="w-full"
                rows={4}
                onFocus={() => setSelectedField('technicalRequirements')}
              />
            </div>
          )}
          {showProspectReq && (
            <div className="mt-4">
              <div className="mb-2 flex items-center justify-between">
                <Label htmlFor="prospectRequirements">Prospect Requirements</Label>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    setValue('prospectRequirements', '')
                    setShowProspectReq(false)
                  }}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <Textarea
                id="prospectRequirements"
                {...register('prospectRequirements')}
                className="w-full"
                rows={4}
                onFocus={() => setSelectedField('prospectRequirements')}
              />
            </div>
          )}
          <div className="mt-6 flex items-center justify-between">
            <Button
              type="button"
              onClick={onSeePrompt}
              className="bg-gray-200 text-gray-700 hover:bg-gray-300"
            >
              <Eye className="mr-2 h-4 w-4" /> See prompt
            </Button>
            <Button
              type="submit"
              disabled={!formIsValid}
              className="bg-blue-600 text-white hover:bg-blue-700"
            >
              Next <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </form>
      </div>
      <div className="w-1/3 bg-gray-100">
        <HelpContent selectedField={selectedField} onExampleClick={handleExampleClick} />
      </div>
    </div>
  )
}

const PromptStep = ({
  prompt,
  onPromptChange,
  onBack,
  onNext,
}: {
  prompt: string
  onPromptChange: (prompt: string) => void
  onBack: () => void
  onNext: () => void
}) => {
  const [selectedField, setSelectedField] = useState<string | null>(null)

  const handleExampleClick = (field: string, value: string) => {
    onPromptChange(prompt + '\n' + value)
  }

  return (
    <div className="flex h-full">
      <div className="flex w-2/3 flex-col p-6">
        <h2 className="mb-4 text-2xl font-bold">Prompt preview</h2>
        <Textarea
          value={prompt}
          onChange={e => onPromptChange(e.target.value)}
          placeholder="Edit or add more to your prompt..."
          className="flex-grow resize-none overflow-y-auto border-none p-0 text-base focus:ring-0"
          onFocus={() => setSelectedField('prompt')}
        />
        <div className="mt-6 flex items-center justify-between border-t pt-4">
          <Button
            type="button"
            onClick={onBack}
            className="border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
          >
            <ArrowLeft className="mr-2 h-4 w-4" /> Back
          </Button>
          <Button
            type="button"
            onClick={onNext}
            className="bg-blue-600 text-white hover:bg-blue-700"
          >
            Next <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
      <div className="w-1/3 bg-gray-100">
        <HelpContent selectedField={selectedField} onExampleClick={handleExampleClick} />
      </div>
    </div>
  )
}

const BriefScreen = ({
  formValues,
  prompt,
  onBack,
  onSubmit,
}: {
  formValues: FormData
  prompt: string
  onBack: () => void
  onSubmit: () => void
}) => {
  return (
    <div className="flex h-full flex-col p-6">
      <Card className="flex-grow overflow-y-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Proof of Concept for {formValues.prospectName || 'Unnamed Prospect'}
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-semibold">Prospect:</h3>
              <p>{formValues.prospectName || 'Not provided'}</p>
            </div>
            <div>
              <h3 className="font-semibold">Website:</h3>
              <p>{formValues.prospectWebsite || 'Not provided'}</p>
            </div>
          </div>
          <div>
            <h3 className="mb-2 font-semibold">Objective:</h3>
            <p>{formValues.whatToBuild || 'Not specified'}</p>
          </div>
          <div>
            <h3 className="mb-2 font-semibold">Technology:</h3>
            {formValues.technologies && formValues.technologies.length > 0 ? (
              <p>{formValues.technologies.map(tech => tech.label).join(', ')}</p>
            ) : (
              <p>Not specified</p>
            )}
          </div>
          <div>
            <h3 className="mb-2 font-semibold">Technical Requirements:</h3>
            <p>{formValues.technicalRequirements || 'None specified'}</p>
          </div>
          <div>
            <h3 className="mb-2 font-semibold">Prospect Requirements:</h3>
            <p>{formValues.prospectRequirements || 'None specified'}</p>
          </div>
          <div>
            <h3 className="mb-2 font-semibold">Prompt:</h3>
            <p className="whitespace-pre-wrap">{prompt || 'No prompt provided'}</p>
          </div>
        </CardContent>
      </Card>
      <div className="mt-6 flex items-center justify-between border-t pt-4">
        <Button
          type="button"
          onClick={onBack}
          className="border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          className="bg-blue-600 text-white hover:bg-blue-700"
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default function Component({ onCreateIteration }: { onCreateIteration: (any) => void }) {
  const [step, setStep] = useState<'form' | 'prompt' | 'brief'>('form')
  const [formValues, setFormValues] = useState<FormData>({
    prospectName: '',
    prospectWebsite: '',
    projectTemplate: null,
    technologies: null,
    whatToBuild: '',
    technicalRequirements: '',
    prospectRequirements: '',
  })
  const [prompt, setPrompt] = useState('Write a hello world app in python')

  const handleFormSubmit = (data: FormData) => {
    setFormValues(data)
    setStep('prompt')
  }

  const handlePromptChange = (newPrompt: string) => {
    setPrompt(newPrompt)
  }

  const handleSubmit = () => {
    console.log({ ...formValues, prompt })
    onCreateIteration({
      configuration: { ...formValues, technologies: formValues.technologies.map(t => t.value) },
      prompt,
    })
    // Handle final submission here
  }

  const handleSeePrompt = () => {
    setStep('prompt')
  }

  return (
    <div className="mx-auto max-w-6xl overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="h-[calc(100vh-100px)]">
        {step === 'form' && (
          <FormStep
            onSubmit={handleFormSubmit}
            initialValues={formValues}
            onSeePrompt={handleSeePrompt}
          />
        )}
        {step === 'prompt' && (
          <PromptStep
            prompt={prompt}
            onPromptChange={handlePromptChange}
            onBack={() => setStep('form')}
            onNext={() => setStep('brief')}
          />
        )}
        {step === 'brief' && (
          <BriefScreen
            formValues={formValues}
            prompt={prompt}
            onBack={() => setStep('prompt')}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
  )
}
