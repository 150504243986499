import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog.jsx'
import { Description, Field, Label } from '@/components/catalyst/fieldset.jsx'
import { Textarea } from '@/components/catalyst/textarea.jsx'
import { Button } from '@/components/catalyst/button.jsx'
import MCreateIterationForm from '@/components/molecules/iteration-details/CreateIteration/MCreateIterationForm.tsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createIterationForProject } from '@/services/Firebase.js'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { useToast } from '@/components/ui/use-toast.js'

const DEFAULT_PROMPT = "Describe what you'd like to accomplish in this iteration."

// if the current url starts with old. then don't start gunslinger for new iteration

const START_GUNSLINGER_FOR_NEW_ITERATION = window?.location?.host?.startsWith('old.') ? false : true

function useCreateIteration() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new iteration for a project.
   * @param {Object} options - The options for the function.
   * @param {String} options.projectId - The ID of the project.
   * @param {String} options.prompt - The prompt for the iteration.

   * @returns {Promise<Object>} A promise that resolves when the iteration is created.
   */
  const createIteration = async ({ projectId, prompt, sourceIterationId, configuration }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createIterationForProject({
        projectId,
        prompt,
        sourceIterationId,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration,
      })
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateIteration() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createIterationFn: createIteration, resetCreateIteration }
}

export default function MCreateIterationDialog({
  projectId,
  isOpen,
  onClose,
  isFirstIterationForProject = false,
  sourceIterationData = {},
  onIterationCreated = () => {
    console.log('Default function called on MNewIterationDialog')
  },
}) {
  const {
    response,
    createIterationFn,
    error: iterationCreationError,
    isCreating,
    resetCreateIteration: resetCreateIterationFn,
  } = useCreateIteration()

  const { toast } = useToast()

  const sourceIterationId = useMemo(() => {
    return sourceIterationData?.id || null
  }, [sourceIterationData])

  const defaultValues = useMemo(() => {
    return {
      prompt: sourceIterationData?.prompt || DEFAULT_PROMPT,
    }
  }, [sourceIterationData])

  // handle post iteration creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Iteration created successfully! 🎉',
        description:
          'The iteration will start running soon. You can check its status in the project dashboard.',
      })
      onIterationCreated(response?.data || {})
      resetCreateIterationFn()
      onClose()
    }
    if (iterationCreationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating iteration 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating iteration:', iterationCreationError)
      resetCreateIterationFn()
    }
  }, [response, iterationCreationError, toast, resetCreateIterationFn, onClose, onIterationCreated])

  const onSubmit = useCallback(
    ({ prompt, configuration }) => {
      if (projectId && !isCreating) {
        createIterationFn({ projectId, prompt: prompt, sourceIterationId, configuration })
      }
    },
    [projectId, createIterationFn, isCreating, sourceIterationId]
  )

  return (
    <>
      <Dialog size="5xl" open={isOpen} onClose={onClose}>
        <MCreateIterationForm onCreateIteration={onSubmit} />
      </Dialog>
    </>
  )
}
